
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { MockedDataProvider } from './contexts/MockDataProvider';

ReactDOM.render(
  <AuthProvider>
    <React.StrictMode>
      <MockedDataProvider>
        <App />
      </MockedDataProvider>
    </React.StrictMode>
  </AuthProvider>,
  document.getElementById("root")
);