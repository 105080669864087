import React, { useState, useEffect, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListOl,
  FaListUl,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaLink,
  FaImage,
  FaCalendarAlt,
} from "react-icons/fa";

import { DataContext } from "../contexts/MockDataProvider";

const CustomToolbar = ({ handleInsertDate }) => (
  <div id="toolbar" className="flex space-x-2 items-center p-2 bg-gray-100 border-b border-gray-300">
    <button className="ql-bold" title="Bold"><FaBold /></button>
    <button className="ql-italic" title="Italic"><FaItalic /></button>
    <button className="ql-underline" title="Underline"><FaUnderline /></button>
    <button className="ql-list" value="ordered" title="Ordered List"><FaListOl /></button>
    <button className="ql-list" value="bullet" title="Bullet List"><FaListUl /></button>
    <button className="ql-align" value="" title="Align Left"><FaAlignLeft /></button>
    <button className="ql-align" value="center" title="Align Center"><FaAlignCenter /></button>
    <button className="ql-align" value="right" title="Align Right"><FaAlignRight /></button>
    <button className="ql-link" title="Insert Link"><FaLink /></button>
    <button className="ql-image" title="Insert Image"><FaImage /></button>
    <button onClick={handleInsertDate} title="Insert Date" className="cursor-pointer">
      <FaCalendarAlt />
    </button>
  </div>
);

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

const SpecialistList = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [content, setContent] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [language, setLanguage] = useState("en"); // Language state for configuration
  const { data } = useContext(DataContext);
  const { specialistData, loading, error } = data;

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleInsertDate = () => {
    const editor = document.querySelector(".ql-editor");
    const date = new Date().toLocaleDateString();
    editor.innerHTML += `<p>${date}</p>`;
  };

  const toggleTag = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  useEffect(() => {
    const textContent = content.replace(/<[^>]+>/g, ""); // Strip HTML tags
    setWordCount(textContent.trim().split(/\s+/).filter(Boolean).length);
    setCharCount(textContent.length);
  }, [content]);

  const filteredSpecialists = specialistData.filter((role) => {
    const matchesSearchTerm = role.label.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTags = selectedTags.length
      ? selectedTags.every((tag) => role.tags?.includes(tag))
      : true;

    return matchesSearchTerm && matchesTags;
  });

  if (loading) return <p>Loading specialists...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!specialistData || specialistData.length === 0)
    return <p>No specialists available.</p>;

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Specialist List</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Settings Column */}
        <div className="space-y-6">
          {/* Language Selector */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Select Language</label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="en">English</option>
              <option value="pl">Polish</option>
            </select>
          </div>

          {/* Search */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Search Specialists</label>
            <input
              type="text"
              placeholder="Search by role..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          {/* Selected Tags */}
          {selectedTags.length > 0 && (
            <div className="space-y-2">
              <p className="text-sm font-medium text-gray-700">Selected Tags:</p>
              <div className="flex flex-wrap gap-2">
                {selectedTags.map((tag, index) => (
                  <span
                    key={index}
                    onClick={() => toggleTag(tag)}
                    className="px-2 py-1 bg-blue-200 text-blue-700 rounded-lg cursor-pointer"
                  >
                    {tag} &times;
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Specialist List Column */}
        <div className="overflow-y-auto max-h-[50vh] flex flex-col">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
    {filteredSpecialists.map((role) => (
      <div
        key={role.value}
        onClick={() => setSelectedRole(role.value)}
        className={`p-4 border rounded-lg cursor-pointer hover:shadow-md ${
          selectedRole === role.value ? "bg-blue-100 border-blue-500" : "bg-white"
        }`}
      >
        <h2 className="text-lg font-medium text-gray-800">{role.label}</h2>
        <p className="text-gray-600 mt-1">
          {role.description || "General"}
        </p>
        <div className="mt-2 flex flex-wrap gap-2">
          {role.tags?.split("|").map((tag, index) => (
            <span
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                toggleTag(tag.trim());
              }}
              className={`px-2 py-1 rounded-lg cursor-pointer ${
                selectedTags.includes(tag.trim())
                  ? "bg-blue-200 text-blue-700"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {tag.trim()}
            </span>
          ))}
        </div>
      </div>
    ))}
  </div>
</div>
      </div>

      {/* Two Column Layout */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 mt-6">
        {/* WYSIWYG Editor */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
          <div className="bg-white border border-gray-300 rounded-lg overflow-hidden">
            <CustomToolbar handleInsertDate={handleInsertDate} />
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleContentChange}
              modules={modules}
              className="h-[300px] overflow-auto"
            />
          </div>
          <div className="flex justify-between mt-2 text-sm text-gray-600">
            <span>Words: {wordCount}</span>
            <span>Characters: {charCount}</span>
          </div>
        </div>

        {/* Response Area */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Response</label>
          <div className="p-4 border border-gray-300 rounded-lg bg-white h-[300px] overflow-auto">
            <h2 className="text-lg font-medium text-gray-800">Selected Role</h2>
            <p className="text-gray-600 mt-2">{selectedRole || "None selected"}</p>

            <h2 className="text-lg font-medium text-gray-800 mt-4">Content</h2>
            <div className="text-gray-600 mt-2" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <button
        className="mt-6 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        onClick={() => alert(`Language: ${language}, Role: ${selectedRole}, Content: ${content}`)}
      >
        Submit
      </button>
    </div>
  );
};

export default SpecialistList;
