import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { FaReact, FaNodeJs } from "react-icons/fa"; // Icons for technologies
import { SiTailwindcss } from "react-icons/si";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // Redirect if already authenticated
  if (isAuthenticated) {
    navigate("/");
  }

  const handleLogin = () => {
    if (username === "admin" && password === "admin") {
      login();
      navigate("/dashboard");
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gray-100 overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 pointer-events-none bg-gradient-to-br from-blue-200 via-purple-200 to-pink-100 animate-gradient blur-2xl opacity-50"></div>

      {/* Floating Shapes */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute w-96 h-96 bg-blue-400 rounded-full blur-3xl opacity-30 top-1/4 left-1/4 animate-bounce-slow"></div>
        <div className="absolute w-72 h-72 bg-purple-400 rounded-full blur-3xl opacity-30 top-3/4 right-1/3 animate-bounce-slower"></div>
      </div>

      {/* Floating Tech Icons */}
      <div className="absolute inset-0 z-0 pointer-events-none">
        <FaReact
          className="absolute text-blue-500 opacity-70 text-8xl animate-spin-slow top-10 left-10"
        />
        <FaNodeJs
          className="absolute text-green-500 opacity-70 text-8xl animate-bounce top-2/4 left-2/4"
        />
        <SiTailwindcss
          className="absolute text-blue-300 opacity-70 text-8xl animate-pulse bottom-10 right-20"
        />
      </div>

      {/* Login Form */}
      <div className="relative z-10 w-full max-w-md bg-white p-8 rounded-2xl shadow-xl">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">Login</h1>
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none placeholder-gray-400"
            placeholder="Enter your username"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none placeholder-gray-400"
            placeholder="Enter your password"
          />
        </div>
        <button
          onClick={handleLogin}
          className="w-full bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 transition"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
