import React, { useEffect } from "react";
import { FaDocker, FaNodeJs, FaBrain, FaReact, FaGitAlt } from "react-icons/fa";
import { SiPhp, SiJavascript, SiLinux, SiTensorflow, SiSymfony, SiOpenai } from "react-icons/si";
import "./styles.css";

// Translations object
const translations = {
  en: {
    title: "Welcome to makememore",
    description:
      "At makememore, we deliver innovative technology solutions designed to help businesses thrive in the digital era. Empower your future with cutting-edge tools and expertise.",
    buttonText: "See more",
  },
  pl: {
    title: "Witamy w makememore",
    description:
      "W makememore dostarczamy innowacyjne rozwiązania technologiczne, które pomagają firmom odnosić sukcesy w erze cyfrowej. Wzmocnij swoją przyszłość za pomocą najnowocześniejszych narzędzi i wiedzy.",
    buttonText: "Zobacz więcej",
  },
  es: {
    title: "Bienvenido a makememore",
    description:
      "En makememore, ofrecemos soluciones tecnológicas innovadoras diseñadas para ayudar a las empresas a prosperar en la era digital. Potencia tu futuro con herramientas avanzadas y experiencia.",
    buttonText: "Ver más",
  },
};

const Hero = ({ language }) => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const elements = document.querySelectorAll("#background-icons > div");
      const { clientX: mouseX, clientY: mouseY } = event;

      elements.forEach((element, index) => {
        const speed = (index + 1) * 0.03;
        const x = (window.innerWidth / 2 - mouseX) * speed;
        const y = (window.innerHeight / 2 - mouseY) * speed;

        element.style.transform = `translate(${x}px, ${y}px)`;
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const scrollToSolutions = () => {
    const solutionsSection = document.getElementById("solutions");
    if (solutionsSection) {
      solutionsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const icons = [
    <FaDocker className="text-blue-400 transition-transform duration-200" />,
    <SiPhp className="text-purple-400 transition-transform duration-300" />,
    <SiJavascript className="text-yellow-400 transition-transform duration-400" />,
    <SiLinux className="text-gray-500 transition-transform duration-500" />,
    <FaNodeJs className="text-green-400 transition-transform duration-600" />,
    <SiTensorflow className="text-orange-400 transition-transform duration-700" />,
    <FaBrain className="text-indigo-400 transition-transform duration-800" />,
    <FaReact className="text-cyan-400 transition-transform duration-900" />,
    <SiSymfony className="text-black transition-transform duration-1000" />,
    <SiOpenai className="text-teal-400 transition-transform duration-1100" />,
    <FaGitAlt className="text-red-500 transition-transform duration-1200" />,
  ];

  const duplicatedIcons = Array(2)
    .fill(icons)
    .flat();

  const t = translations[language] || translations.en;

  return (
    <section
      id="hero"
      className="relative min-h-screen flex items-center justify-center bg-fixed bg-gradient-to-br from-blue-800 via-purple-900 to-gray-900 text-center overflow-hidden"
    >
      {/* Background Icons */}
      <div id="background-icons" className="absolute inset-0 z-0 pointer-events-none">
        {duplicatedIcons.map((Icon, i) => (
          <div
            key={i}
            className={`absolute text-6xl opacity-30 blur-sm`}
            style={{
              top: `${10 + Math.random() * 80}%`,
              left: `${10 + Math.random() * 80}%`,
              animation: `float-${i % 2 === 0 ? "up" : "down"} ${
                3 + Math.random() * 5
              }s ease-in-out infinite`,
            }}
          >
            {Icon}
          </div>
        ))}
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-4xl mx-auto text-center p-8">
        <h1
          className="text-5xl font-extrabold text-white sm:text-6xl mb-6"
          style={{ textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)" }}
        >
          {t.title}
        </h1>
        <p
          className="text-lg text-gray-300 leading-relaxed max-w-2xl mx-auto mb-8"
          style={{ textShadow: "1px 1px 6px rgba(0, 0, 0, 0.7)" }}
        >
          {t.description}
        </p>
        <button
          onClick={scrollToSolutions}
          className="px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-600 transition"
        >
          {t.buttonText}
        </button>
      </div>
    </section>
  );
};

export default Hero;
