import React, { useState } from "react";
import Hero from "../components/Hero";
import SolutionsSection from "../components/SolutionsSection";
import AiSection from "../components/AiSection";
import TechnologiesSection from "../components/TechnologiesSection";
import OfferSection from "../components/OfferSection";
import CookieConsent from "../components/CookieConsent";
import RecommendedSection from "../components/RecommendedSection";

const LandingPage = () => {
  const [language, setLanguage] = useState("en");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const selectLanguage = (lang) => {
    setLanguage(lang);
    setIsDropdownOpen(false);
  };

  return (
    <div className="bg-gray-100 text-gray-800 relative">
      {/* Language Selector */}
      <div className="absolute top-4 right-4 z-50">
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center justify-center px-4 py-2 bg-gray-800 text-white rounded-lg shadow-lg focus:outline-none"
          >
            {language.toUpperCase()}
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
              <ul className="text-gray-700">
                <li
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => selectLanguage("en")}
                >
                  EN - English
                </li>
                <li
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => selectLanguage("pl")}
                >
                  PL - Polski
                </li>
                <li
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => selectLanguage("es")}
                >
                  ES - Español
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <Hero language={language} />
      <OfferSection language={language} />
      <SolutionsSection language={language} />
      <AiSection language={language} />
      <TechnologiesSection language={language} />
      <RecommendedSection language={language} />

      {/* Footer */}
      <footer className="py-12 bg-gray-900 text-gray-300 text-center">
        <div className="max-w-6xl mx-auto px-6">
          <p className="mt-8 text-sm text-gray-400">
            © 2013-{new Date().getFullYear()} makememore.eu. All rights reserved.
          </p>
        </div>
      </footer>

      {/* Cookie Consent */}
      <CookieConsent language={language} />
    </div>
  );
};

export default LandingPage;
