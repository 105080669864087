import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import SpecialistList from "./pages/SpecialistList";
import CrudList from "./pages/CrudList";
import LoginPage from "./pages/LoginPage";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage"; // Import the new LandingPage
import { AuthContext } from "./contexts/AuthContext";
import Calendar from "./pages/Calendar";

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <div className="flex h-screen bg-gray-50">
        {/* Show Sidebar only when authenticated */}
        {isAuthenticated && <Sidebar />}
        <div className="flex-1">
          <Routes>
            {/* Public Route for Landing Page */}
            <Route path="/" element={<LandingPage />} />
            
            {/* Login Page */}
            <Route path="/login" element={<LoginPage />} />
            
            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/crud"
              element={
                <PrivateRoute>
                  <CrudList />
                </PrivateRoute>
              }
            />
            <Route
              path="/specialists"
              element={
                <PrivateRoute>
                  <SpecialistList />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <PrivateRoute>
                  <Calendar />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
