
const AiSection = ({ language }) => {

  return (
<section className="py-20 bg-gray-50 text-center">
  <div className="max-w-6xl mx-auto px-6">
    <h2 className="text-5xl font-extrabold text-gray-800 mb-8 animate-fade-in">
      AI Tools & Intelligent Agents
    </h2>
    <p className="text-lg text-gray-700 leading-relaxed mb-12">
      Discover cutting-edge AI technologies designed to empower automation, enhance decision-making, and drive innovation. From virtual assistants to intelligent agents, we bring AI solutions to life.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
      <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:-translate-y-1">
        <div className="mb-4">
          <span className="inline-block bg-purple-100 text-purple-600 text-sm font-medium px-3 py-1 rounded-full">
            AI Innovation
          </span>
        </div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Custom AI Models
        </h3>
        <p className="text-gray-600">
          Develop tailored AI models that align with your unique business goals, optimized for precision and performance.
        </p>
      </div>
      <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:-translate-y-1">
        <div className="mb-4">
          <span className="inline-block bg-teal-100 text-teal-600 text-sm font-medium px-3 py-1 rounded-full">
            Conversational AI
          </span>
        </div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Intelligent Chatbots
        </h3>
        <p className="text-gray-600">
          Build advanced conversational agents to provide seamless customer support and boost user engagement.
        </p>
      </div>
      <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:-translate-y-1">
        <div className="mb-4">
          <span className="inline-block bg-yellow-100 text-yellow-600 text-sm font-medium px-3 py-1 rounded-full">
            Data Insights
          </span>
        </div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
          Predictive Analytics
        </h3>
        <p className="text-gray-600">
          Leverage AI-driven insights to forecast trends and enable smarter, data-backed decision-making.
        </p>
      </div>
    </div>
  </div>
</section>
  );

};

export default AiSection;
