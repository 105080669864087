import React, { useState, useEffect } from "react";

const translations = {
  en: {
    message: "We use cookies to improve your experience. By continuing, you agree to our cookie policy.",
    accept: "Accept",
  },
  pl: {
    message: "Używamy plików cookie, aby poprawić Twoje doświadczenia. Kontynuując, akceptujesz naszą politykę plików cookie.",
    accept: "Akceptuj",
  },
  es: {
    message: "Usamos cookies para mejorar su experiencia. Al continuar, acepta nuestra política de cookies.",
    accept: "Aceptar",
  },
};

const CookieConsent = ({ language = "en" }) => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieAccepted") === "true";
    setIsCookieAccepted(consent);
  }, []);

  const acceptCookies = () => {
    setIsCookieAccepted(true);
    localStorage.setItem("cookieAccepted", "true");
  };

  const t = translations[language] || translations.en; // Fallback to English

  if (isCookieAccepted) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white py-4 px-6 z-50">
      <div className="flex justify-between items-center max-w-6xl mx-auto">
        <p className="text-sm">{t.message}</p>
        <button
          onClick={acceptCookies}
          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
        >
          {t.accept}
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
