// src/pages/Dashboard.js
import React from "react";
import Header from "../components/Header";
import StatCard from "../components/StatCard";
import Chart from "../components/Chart";

const Dashboard = () => {
  return (
    <div className="flex-1 flex flex-col h-full bg-gray-100">
      {/* Sticky Header */}
      <div className="sticky top-0 z-10 bg-white shadow">
        <Header title="Overview" />
      </div>

      {/* Main Content */}
      <main className="flex-1 overflow-y-auto p-6 space-y-6">
        {/* Statistic Cards */}
        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <StatCard title="Total Users" value="1,245" />
          <StatCard title="Revenue" value="$8,430" />
          <StatCard title="Active Projects" value="12" />
        </section>

        {/* Charts Section */}
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Performance Overview</h2>
          <Chart />
        </section>

        {/* Additional Content */}
        <section className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Recent Activities</h2>
          <ul className="space-y-4">
            <li className="p-4 bg-gray-50 rounded-lg shadow">
              User <strong>John Doe</strong> signed up.
            </li>
            <li className="p-4 bg-gray-50 rounded-lg shadow">
              New project <strong>Website Redesign</strong> created.
            </li>
            <li className="p-4 bg-gray-50 rounded-lg shadow">
              Revenue target for Q1 achieved!
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
