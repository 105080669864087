import React from "react";
import { FaDocker, FaNodeJs, FaBrain, FaReact, FaPython, FaRobot } from "react-icons/fa";
import { SiPhp, SiJavascript, SiLinux, SiTensorflow, SiPytorch, SiSymfony } from "react-icons/si";

// Translations object
const translations = {
  en: {
    title: "Technologies We Use",
    description:
      "Our stack blends modern frameworks, AI libraries, and robust tools to create reliable, scalable, and innovative solutions tailored to your needs.",
  },
  pl: {
    title: "Technologie, które używamy",
    description:
      "Nasz stos łączy nowoczesne frameworki, biblioteki AI i solidne narzędzia, aby tworzyć niezawodne, skalowalne i innowacyjne rozwiązania dostosowane do Twoich potrzeb.",
  },
  es: {
    title: "Tecnologías que usamos",
    description:
      "Nuestra pila combina marcos modernos, bibliotecas de IA y herramientas robustas para crear soluciones confiables, escalables e innovadoras adaptadas a sus necesidades.",
  },
};

const TechnologiesSection = ({ language }) => {
  const t = translations[language] || translations.en; // Fallback to English if language not supported

  return (
    <section className="py-20 bg-gray-50 text-center">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-5xl font-extrabold text-gray-800 mb-10 animate-slide-in">
          {t.title}
        </h2>
        <p className="mb-12 text-lg text-gray-600">{t.description}</p>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-10 text-6xl text-gray-500">
          <div className="flex flex-col items-center group">
            <FaDocker className="transition-transform transform group-hover:scale-110 hover:text-blue-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-blue-500">Docker</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiPhp className="transition-transform transform group-hover:scale-110 hover:text-purple-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-purple-500">PHP</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiJavascript className="transition-transform transform group-hover:scale-110 hover:text-yellow-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-yellow-500">JavaScript</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiLinux className="transition-transform transform group-hover:scale-110 hover:text-gray-800" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-gray-800">Linux</span>
          </div>
          <div className="flex flex-col items-center group">
            <FaNodeJs className="transition-transform transform group-hover:scale-110 hover:text-green-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-green-500">Node.js</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiTensorflow className="transition-transform transform group-hover:scale-110 hover:text-orange-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-orange-500">TensorFlow</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiPytorch className="transition-transform transform group-hover:scale-110 hover:text-red-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-red-500">PyTorch</span>
          </div>
          <div className="flex flex-col items-center group">
            <FaBrain className="transition-transform transform group-hover:scale-110 hover:text-indigo-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-indigo-500">AI/ML</span>
          </div>
          <div className="flex flex-col items-center group">
            <FaPython className="transition-transform transform group-hover:scale-110 hover:text-blue-400" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-blue-400">Python</span>
          </div>
          <div className="flex flex-col items-center group">
            <FaReact className="transition-transform transform group-hover:scale-110 hover:text-blue-600" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-blue-600">React</span>
          </div>
          <div className="flex flex-col items-center group">
            <SiSymfony className="transition-transform transform group-hover:scale-110 hover:text-black" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-black">Symfony</span>
          </div>
          <div className="flex flex-col items-center group">
            <FaRobot className="transition-transform transform group-hover:scale-110 hover:text-teal-500" />
            <span className="mt-2 text-sm text-gray-600 group-hover:text-teal-500">Agents</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologiesSection;
