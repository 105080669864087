import React, { createContext, useState, useEffect } from 'react';

// Mock data for demonstration
const mockUserData = { id: 1, name: 'John Doe', email: 'john.doe@example.com' };

const mockSpecialistData = [
    { 
      id: 1, 
      label: 'Marketing - Campaign Strategy', 
      value: 'marketing-campaign-strategy', 
      tags: '#marketing|#strategy', 
      description: 'Responsible for creating and executing marketing campaigns.' 
    },
    { 
      id: 2, 
      label: 'Marketing - Analyze Market Trends', 
      value: 'marketing-analyze-trends', 
      tags: '#marketing|#analysis', 
      description: 'Analyzes market trends and consumer behavior to inform strategies.'
    },
    { 
      id: 3, 
      label: 'Marketing - Optimize Brand Strategies', 
      value: 'marketing-optimize-strategies', 
      tags: '#marketing|#branding', 
      description: 'Optimizes brand strategies to enhance market presence.'
    },
    { 
      id: 4, 
      label: 'Programmer - Code Analysis', 
      value: 'programmer-code-analysis', 
      tags: '#development|#performance', 
      description: 'Analyzes code for performance and maintainability.' 
    },
    { 
      id: 5, 
      label: 'Programmer - Check Security', 
      value: 'programmer-check-security', 
      tags: '#development|#security', 
      description: 'Ensures application security by identifying vulnerabilities.' 
    },
    { 
      id: 6, 
      label: 'Programmer - Write Code', 
      value: 'programmer-write-code', 
      tags: '#development|#coding', 
      description: 'Writes clean, efficient, and maintainable code.' 
    },
    { 
      id: 7, 
      label: 'Project Manager - Create Tasks', 
      value: 'pm-create-tasks', 
      tags: '#management|#tasks', 
      description: 'Creates detailed tasks and assigns them to team members.' 
    },
    { 
      id: 8, 
      label: 'Project Manager - Coordinate Teams', 
      value: 'pm-coordinate-teams', 
      tags: '#management|#teams', 
      description: 'Coordinates team efforts to ensure smooth collaboration.' 
    },
    { 
      id: 9, 
      label: 'Project Manager - Monitor Progress', 
      value: 'pm-monitor-progress', 
      tags: '#management|#progress', 
      description: 'Monitors project progress and adjusts plans as necessary.' 
    },
    { 
      id: 10, 
      label: 'Data Analyst - Generate Reports', 
      value: 'data-analyst-generate-reports', 
      tags: '#analytics|#reports', 
      description: 'Generates detailed reports to present findings.' 
    },
    { 
      id: 11, 
      label: 'Data Analyst - Interpret Data', 
      value: 'data-analyst-interpret-data', 
      tags: '#analytics|#data', 
      description: 'Interprets data to uncover trends and actionable insights.' 
    },
    { 
      id: 12, 
      label: 'Data Analyst - Support Decision-Making', 
      value: 'data-analyst-decision-making', 
      tags: '#analytics|#decision-support', 
      description: 'Supports decision-making by providing data-driven recommendations.' 
    },
    { 
      id: 13, 
      label: 'Copywriter - Write Content', 
      value: 'copywriter-write-content', 
      tags: '#copywriting|#content', 
      description: 'Writes engaging content for marketing campaigns and websites.' 
    },
    { 
      id: 14, 
      label: 'Copywriter - Create Campaign Slogans', 
      value: 'copywriter-create-slogans', 
      tags: '#copywriting|#branding', 
      description: 'Develops creative and memorable slogans for campaigns.' 
    },
    { 
      id: 15, 
      label: 'Copywriter - Edit and Proofread', 
      value: 'copywriter-edit-proofread', 
      tags: '#copywriting|#editing', 
      description: 'Edits and proofreads content to ensure quality and consistency.' 
    }
  ];
  
// Simulated delay function
const simulateNetworkDelay = (result, delay = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(result), delay));

// Create the context
export const DataContext = createContext();

// Mocked Data Provider
export const MockedDataProvider = ({ children }) => {
  const [data, setData] = useState({
    user: null,
    specialistData: null,
    isAuthenticated: false,
    loading: false,
    error: null,
  });

  // Mock fetch user data
  const fetchUserData = async () => {
    setData((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const response = await simulateNetworkDelay(mockUserData, 1000);
      setData((prev) => ({
        ...prev,
        user: response,
        isAuthenticated: true,
        loading: false,
        error: null,
      }));
    } catch (error) {
      setData((prev) => ({
        ...prev,
        user: null,
        isAuthenticated: false,
        loading: false,
        error: 'Failed to fetch user data',
      }));
    }
  };

  // Mock fetch specialist data
  const fetchSpecialistData = async () => {
    setData((prev) => ({ ...prev, loading: true, error: null }));
    try {
      const response = await simulateNetworkDelay(mockSpecialistData, 1000);
      setData((prev) => ({
        ...prev,
        specialistData: response,
        loading: false,
        error: null,
      }));
    } catch (error) {
      setData((prev) => ({
        ...prev,
        specialistData: null,
        loading: false,
        error: 'Failed to fetch specialist data',
      }));
    }
  };

  // Mock login function
  const login = async (credentials) => {
    setData((prev) => ({ ...prev, loading: true, error: null }));
    try {
      await simulateNetworkDelay(null, 1000);
      if (credentials.username === 'admin' && credentials.password === 'admin') {
        setData((prev) => ({
          ...prev,
          user: mockUserData,
          isAuthenticated: true,
          loading: false,
          error: null,
        }));
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      setData((prev) => ({
        ...prev,
        loading: false,
        error: error.message,
      }));
    }
  };

  // Mock logout function
  const logout = async () => {
    await simulateNetworkDelay(null, 500);
    setData((prev) => ({
      ...prev,
      user: null,
      isAuthenticated: false,
      loading: false,
      error: null,
    }));
  };

  // Fetch user and specialist data on mount
  useEffect(() => {
    fetchUserData();
    fetchSpecialistData();
  }, []);

  return (
    <DataContext.Provider value={{ data, login, logout, fetchUserData, fetchSpecialistData }}>
      {children}
    </DataContext.Provider>
  );
};
