import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slider styles
import "slick-carousel/slick/slick-theme.css";

const translations = {
  en: {
    title: "Recommended",
  },
  pl: {
    title: "Polecamy",
  },
  es: {
    title: "Recomendado",
  },
};

const RecommendedSection = ({ language }) => {
  const t = translations[language] || translations.en; // Fallback to English

  const sliderSettings = {
    dots: false, // Remove dots for a cleaner slot-machine effect
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000, // Faster for slot machine effect
    vertical: true, // Enable vertical sliding
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
  };

  const banners = [
    {
      link: "https://zenbox.pl/polecam/#594",
      imgSrc: "https://panel.zenbox.pl/file-pp/012021/728x90.png",
      alt: "Zenbox Recommended",
    },
    {
      link: "https://auth.zondacrypto.exchange/ref/Xf6gXbTKhVNO/55/pl-PL",
      imgSrc: "https://folder.zondacrypto.com/pl/zondacrypto-image/PPT9PL.png",
      alt: "ZondaCrypto - Największa Polska giełda cyfrowych walut",
    },
  ];

  return (
    <section className="py-20 bg-gray-100 flex items-center justify-center text-center">
      <div className="max-w-4xl w-full">
        <h2 className="text-5xl font-extrabold text-gray-800 mb-10">
          {t.title}
        </h2>
        <div className="relative overflow-hidden">
        <Slider {...sliderSettings}>
  {banners.map((banner, index) => (
    <div
      key={index}
      className="flex items-center justify-center h-40" // Adjust height as needed
    >
      <a href={banner.link} target="_blank" rel="noopener noreferrer">
        <img
          src={banner.imgSrc}
          alt={banner.alt}
          className="block max-w-full h-auto"
        />
      </a>
    </div>
  ))}
</Slider>


        </div>
      </div>
    </section>
  );
};

export default RecommendedSection;
