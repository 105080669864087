// src/pages/Settings.js
import React from "react";
import Header from "../components/Header";

const Settings = () => {
  return (
    <div className="flex-1 flex flex-col">
      <Header title="Settings" />
      <main className="flex-1 p-6">
        <div className="bg-white border border-gray-200 rounded-lg p-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">User Settings</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">Username</label>
              <input
                type="text"
                className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                placeholder="Enter your username"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Email</label>
              <input
                type="email"
                className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Password</label>
              <input
                type="password"
                className="w-full mt-1 p-2 border border-gray-300 rounded-lg"
                placeholder="Enter a new password"
              />
            </div>
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Save Changes
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default Settings;
