// src/pages/Calendar.js
import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Header from "../components/Header";

const Calendar = () => {
  const [events, setEvents] = useState([]);

  const handleDateClick = (info) => {
    const title = prompt("Enter event title:");
    if (title) {
      setEvents((prevEvents) => [
        ...prevEvents,
        { title, start: info.dateStr },
      ]);
    }
  };

  const handleEventClick = (info) => {
    if (window.confirm(`Delete event "${info.event.title}"?`)) {
      info.event.remove();
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <Header title="Calendar" />
      <main className="flex-1 p-6">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          selectable={true}
          editable={true}
        />
      </main>
    </div>
  );
};

export default Calendar;
