// src/components/StatCard.js
import React from "react";

const StatCard = ({ title, value }) => {
  return (
    <div className="p-5 bg-white border border-gray-200 rounded-lg">
      <h2 className="text-sm font-medium text-gray-600">{title}</h2>
      <p className="text-3xl font-bold text-gray-800 mt-1">{value}</p>
    </div>
  );
};

export default StatCard;
