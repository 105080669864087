import React, { useState } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

const CrudList = () => {
  const [news, setNews] = useState([
    { id: 1, title: "React 18 Released", date: "2024-01-01", status: "active" },
    { id: 2, title: "JavaScript Trends 2024", date: "2024-01-05", status: "inactive" },
    { id: 3, title: "Top Tailwind Tips", date: "2024-01-10", status: "active" },
  ]);
  const [newTitle, setNewTitle] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");

  // Add a new news item
  const addNews = () => {
    if (newTitle.trim()) {
      const newId = news.length ? Math.max(...news.map((item) => item.id)) + 1 : 1;
      setNews([...news, { id: newId, title: newTitle.trim(), date: new Date().toISOString().split("T")[0], status: "active" }]);
      setNewTitle("");
    }
  };

  // Delete a news item with confirmation
  const deleteNews = (id) => {
    const confirm = window.confirm("Are you sure you want to delete this news item?");
    if (confirm) {
      setNews(news.filter((item) => item.id !== id));
    }
  };

  // Start editing a news item
  const startEditing = (id, title) => {
    setEditingId(id);
    setEditingTitle(title);
  };

  // Save edited news item
  const saveEdit = () => {
    setNews(news.map((item) => (item.id === editingId ? { ...item, title: editingTitle } : item)));
    setEditingId(null);
    setEditingTitle("");
  };

  // Toggle status
  const toggleStatus = (id) => {
    setNews(news.map((item) => (item.id === id ? { ...item, status: item.status === "active" ? "inactive" : "active" } : item)));
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">News Management</h1>

      {/* Add News */}
      <div className="flex space-x-4 mb-6">
        <input
          type="text"
          placeholder="Add a new news title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          className="flex-1 p-2 border border-gray-300 rounded-lg"
        />
        <button
          onClick={addNews}
          className="px-4 py-2 border border-gray-300 rounded-lg flex items-center space-x-2 hover:bg-gray-100"
        >
          <FaPlus />
          <span>Add</span>
        </button>
      </div>

      {/* News Table */}
      <div className="overflow-auto bg-white border border-gray-200 rounded-lg">
        <table className="w-full text-left border-collapse">
          <thead className="bg-gray-100 text-gray-600 text-sm uppercase tracking-wide">
            <tr>
              <th className="p-3 border-b font-medium">#</th>
              <th className="p-3 border-b font-medium">Title</th>
              <th className="p-3 border-b font-medium">Date</th>
              <th className="p-3 border-b font-medium text-center">Status</th>
              <th className="p-3 border-b font-medium text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {news.map((item, index) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="p-3 border-b text-gray-700 text-sm">{index + 1}</td>
                <td className="p-3 border-b text-gray-700 text-sm">
                  {editingId === item.id ? (
                    <input
                      type="text"
                      value={editingTitle}
                      onChange={(e) => setEditingTitle(e.target.value)}
                      className="p-2 border border-gray-300 rounded-lg w-full"
                    />
                  ) : (
                    item.title
                  )}
                </td>
                <td className="p-3 border-b text-gray-700 text-sm">{item.date}</td>
                <td className="p-3 border-b text-center">
                  <button
                    onClick={() => toggleStatus(item.id)}
                    className="flex items-center justify-center space-x-2 focus:outline-none"
                  >
                    <span
                      className={`h-3 w-3 rounded-full ${
                        item.status === "active" ? "bg-green-500" : "bg-red-500"
                      }`}
                      title={item.status === "active" ? "Active" : "Inactive"}
                    ></span>
                    <span className="text-sm text-gray-700">{item.status}</span>
                  </button>
                </td>
                <td className="p-3 border-b text-center flex justify-center space-x-2">
                  {editingId === item.id ? (
                    <button
                      onClick={saveEdit}
                      className="px-3 py-1 border border-gray-300 rounded-lg flex items-center space-x-1 hover:bg-gray-100"
                    >
                      <FaEdit />
                      <span>Save</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => startEditing(item.id, item.title)}
                      className="px-3 py-1 border border-gray-300 rounded-lg flex items-center space-x-1 hover:bg-gray-100"
                    >
                      <FaEdit />
                      <span>Edit</span>
                    </button>
                  )}
                  <button
                    onClick={() => deleteNews(item.id)}
                    className="px-3 py-1 border border-gray-300 rounded-lg flex items-center space-x-1 hover:bg-gray-100"
                  >
                    <FaTrash />
                    <span>Delete</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CrudList;
