import React from "react";
import { FaCode, FaDesktop, FaDatabase } from "react-icons/fa";

// Translations object
const translations = {
  en: {
    title: "Services We Offer",
    description:
      "We provide a wide range of services to meet your business needs, from custom web solutions to seamless data migrations, ensuring scalable, innovative results.",
    services: {
      webApplications: {
        title: "Web Applications",
        description: "Scalable, secure, and responsive web applications tailored to your business needs.",
      },
      landingPages: {
        title: "Landing Pages",
        description: "High-converting landing pages designed to engage and drive action effectively.",
      },
      dataMigrations: {
        title: "Data Migrations",
        description: "Smooth and efficient data migration services ensuring accuracy and security.",
      },
    },
  },
  pl: {
    title: "Usługi, które oferujemy",
    description:
      "Świadczymy szeroką gamę usług dostosowanych do potrzeb Twojej firmy, od niestandardowych rozwiązań internetowych po bezproblemowe migracje danych, zapewniając skalowalne i innowacyjne wyniki.",
    services: {
      webApplications: {
        title: "Aplikacje Internetowe",
        description: "Skalowalne, bezpieczne i responsywne aplikacje internetowe dostosowane do Twoich potrzeb biznesowych.",
      },
      landingPages: {
        title: "Strony Docelowe",
        description: "Strony docelowe o wysokiej konwersji zaprojektowane tak, aby skutecznie angażować i pobudzać do działania.",
      },
      dataMigrations: {
        title: "Migracje Danych",
        description: "Płynne i efektywne migracje danych zapewniające dokładność i bezpieczeństwo.",
      },
    },
  },
  es: {
    title: "Servicios que ofrecemos",
    description:
      "Ofrecemos una amplia gama de servicios para satisfacer las necesidades de su negocio, desde soluciones web personalizadas hasta migraciones de datos sin problemas, garantizando resultados escalables e innovadores.",
    services: {
      webApplications: {
        title: "Aplicaciones Web",
        description: "Aplicaciones web escalables, seguras y receptivas adaptadas a las necesidades de su negocio.",
      },
      landingPages: {
        title: "Páginas de Aterrizaje",
        description: "Páginas de aterrizaje altamente efectivas diseñadas para atraer y motivar a la acción.",
      },
      dataMigrations: {
        title: "Migraciones de Datos",
        description: "Servicios de migración de datos fluidos y eficientes que garantizan precisión y seguridad.",
      },
    },
  },
};

const OfferSection = ({ language }) => {
  const t = translations[language] || translations.en; // Fallback to English if language not supported

  return (
    <section className="py-20 bg-gray-50 text-center">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-5xl font-extrabold text-gray-800 mb-10 animate-slide-in">
          {t.title}
        </h2>
        <p className="mb-12 text-lg text-gray-600">{t.description}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 text-gray-500">
          {/* Web Applications */}
          <div className="flex flex-col items-center group">
            <FaCode className="text-blue-500 text-6xl transition-transform transform group-hover:scale-110" />
            <span className="mt-4 text-lg font-medium text-gray-700 group-hover:text-blue-500">
              {t.services.webApplications.title}
            </span>
            <p className="mt-2 text-sm text-gray-600">
              {t.services.webApplications.description}
            </p>
          </div>
          {/* Landing Pages */}
          <div className="flex flex-col items-center group">
            <FaDesktop className="text-green-500 text-6xl transition-transform transform group-hover:scale-110" />
            <span className="mt-4 text-lg font-medium text-gray-700 group-hover:text-green-500">
              {t.services.landingPages.title}
            </span>
            <p className="mt-2 text-sm text-gray-600">
              {t.services.landingPages.description}
            </p>
          </div>
          {/* Data Migrations */}
          <div className="flex flex-col items-center group">
            <FaDatabase className="text-purple-500 text-6xl transition-transform transform group-hover:scale-110" />
            <span className="mt-4 text-lg font-medium text-gray-700 group-hover:text-purple-500">
              {t.services.dataMigrations.title}
            </span>
            <p className="mt-2 text-sm text-gray-600">
              {t.services.dataMigrations.description}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
