// src/components/Header.js
import React from "react";

const Header = ({ title }) => {
  return (
    <header className="bg-white border-b border-gray-200 p-4">
      <h1 className="text-xl font-semibold text-gray-800">{title}</h1>
    </header>
  );
};

export default Header;
