import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  return (
    <aside className="w-60 bg-gray-900 text-white flex flex-col">
      {/* Header */}
      <div className="p-6 text-xl font-semibold tracking-wide">Dashboard</div>

      {/* Navigation */}
      <nav className="flex-1 p-4">
        <ul className="space-y-2">
          <li>
            <Link
              to="/"
              className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded"
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/specialists"
              className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded"
            >
              Specialists
            </Link>
          </li>
          <li>
            <Link
              to="/crud"
              className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded"
            >
              CRUD List
            </Link>
          </li>
          <li>
            <Link
              to="/settings"
              className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded"
            >
              Settings
            </Link>
          </li>
          <li>
            <Link
              to="/calendar"
              className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded"
            >
              Calendar
            </Link>
          </li>
        </ul>
      </nav>

      {/* Logout */}
      <button
        onClick={logout}
        className="block p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded transition"
      >
        Logout
      </button>
    </aside>
  );
};

export default Sidebar;
