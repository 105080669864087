import React from "react";

const translations = {
  en: {
    title: "Solutions Tailored to Your Needs",
    description:
      "From MVPs to fully production-ready platforms, we craft comprehensive solutions that align with your budget, schedule, and expectations. Whether you need a simple website or a complex system, we deliver tailored strategies to elevate your business.",
    solutions: [
      {
        tag: "AI & Innovation",
        title: "AI-Powered Solutions",
        description: "Harness the power of artificial intelligence to revolutionize your workflows and boost productivity.",
      },
      {
        tag: "Collaboration",
        title: "Streamlined Collaboration",
        description: "Empower your team with intuitive tools and platforms for seamless collaboration and communication.",
      },
      {
        tag: "Efficiency",
        title: "Process Optimization & Automation",
        description: "Streamline and automate your business processes to save time, reduce costs, and enhance efficiency.",
      },
    ],
  },
  pl: {
    title: "Rozwiązania Dostosowane do Twoich Potrzeb",
    description:
      "Od MVP do w pełni produkcyjnych platform, tworzymy kompleksowe rozwiązania zgodne z Twoim budżetem, harmonogramem i oczekiwaniami. Czy potrzebujesz prostej strony internetowej, czy skomplikowanego systemu, dostarczamy dostosowane strategie, które podniosą Twoją firmę na wyższy poziom.",
    solutions: [
      {
        tag: "AI i Innowacja",
        title: "Rozwiązania oparte na AI",
        description: "Wykorzystaj moc sztucznej inteligencji, aby zrewolucjonizować swoje procesy pracy i zwiększyć produktywność.",
      },
      {
        tag: "Współpraca",
        title: "Usprawniona Współpraca",
        description: "Wzmocnij swój zespół za pomocą intuicyjnych narzędzi i platform do łatwej współpracy i komunikacji.",
      },
      {
        tag: "Efektywność",
        title: "Optymalizacja Procesów i Automatyzacja",
        description: "Usprawnij i zautomatyzuj swoje procesy biznesowe, aby zaoszczędzić czas, zmniejszyć koszty i zwiększyć efektywność.",
      },
    ],
  },
  es: {
    title: "Soluciones Adaptadas a Sus Necesidades",
    description:
      "Desde MVPs hasta plataformas listas para producción, creamos soluciones integrales que se ajustan a su presupuesto, cronograma y expectativas. Ya sea que necesite un sitio web simple o un sistema complejo, ofrecemos estrategias personalizadas para elevar su negocio.",
    solutions: [
      {
        tag: "IA e Innovación",
        title: "Soluciones impulsadas por IA",
        description: "Aproveche el poder de la inteligencia artificial para revolucionar sus flujos de trabajo y aumentar la productividad.",
      },
      {
        tag: "Colaboración",
        title: "Colaboración Simplificada",
        description: "Empodere a su equipo con herramientas y plataformas intuitivas para una colaboración y comunicación perfectas.",
      },
      {
        tag: "Eficiencia",
        title: "Optimización y Automatización de Procesos",
        description: "Optimice y automatice sus procesos comerciales para ahorrar tiempo, reducir costos y aumentar la eficiencia.",
      },
    ],
  },
};

const SolutionsSection = ({ language }) => {
  const t = translations[language] || translations.en;

  return (
    <section id="solutions" className="py-20 bg-gray-100 text-center">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-5xl font-extrabold text-gray-800 mb-8 animate-fade-in">
          {t.title}
        </h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-12">{t.description}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {t.solutions.map((solution, index) => (
            <div
              key={index}
              className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:-translate-y-1"
            >
              <div className="mb-4">
                <span className="inline-block bg-blue-100 text-blue-600 text-sm font-medium px-3 py-1 rounded-full">
                  {solution.tag}
                </span>
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">{solution.title}</h3>
              <p className="text-gray-600">{solution.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SolutionsSection;
