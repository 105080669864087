// src/components/Chart.js
import React from "react";

const Chart = () => {
  return (
    <div className="bg-white border border-gray-200 rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-800 mb-4">Performance Chart</h2>
      <div className="h-64 bg-gray-100 rounded-lg flex items-center justify-center">
        <p className="text-gray-400">Chart Placeholder</p>
      </div>
    </div>
  );
};

export default Chart;
